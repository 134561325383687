<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="content d-flex flex-column flex-lg-row mb-16">
      <div class="left ml-lg-8 mr-lg-5">
        <div class="PromoImage">
          <img src="@/assets/promoImage/Uptick_c4.jpg" alt="" />
        </div>
        <div class="timer d-flex flex-lg-row" v-if="!isPhotographyEnd">
          <div class="days">
              <div class="top">
                 <span class="mr-1">{{d1}}</span>
                 <span class="mr-2">{{d2}}</span>
              </div>
              <div class="bom">
                  DAY
              </div>
          </div>
          <div class="mao">:</div> 
          <div class="hours">
              <div class="top">
                 <span class="mr-1 ml-2">{{h1}}</span>
                 <span class="mr-2">{{h2}}</span>
              </div>
              <div class="bom">
                  HR
              </div>
          </div> 
          <div class="mao">:</div> 
          <div class="minutes">
              <div class="top">
                 <span class="ml-2 mr-1">{{m1}}</span>
                 <span class="mr-2">{{m2}}</span>
              </div>
              <div class="bom">
                  MIN
              </div>
          </div> 
          <div class="mao">:</div> 
          <div class="seconds">
              <div class="top">
                 <span class="ml-2 mr-1">{{s1}}</span>
                 <span class="mr-2">{{s2}}</span>
              </div>
              <div class="bom">
                  SEC
              </div>
          </div> 
           
        </div>
        <!-- <div v-else class="  endDesc">
            <span> The contest has ended at 4th March 12:00 (UTC+8). According to the snapshot, the result will be post via Twitter.</span>
        </div> -->
        <div class="btn mt-6 ">
            <button class="claim" v-if="!isStart">Apply</button>
          <button class="notClaim" v-else  @click="toApply" >Apply</button>
        </div>
      </div>
      <div class="right mt-lg-0 mt-9">
        <div class="title mt-2 mb-lg-6">
          <span>Uptick & IRISnet NFT Collection Launchpad Event</span>
        </div>
        <div class="desc mb-lg-6">
          <div class="celebrate">
          We will select up to 30 entries, and in order to support the growth of these collections, Uptick will provide several promotional elements to help along with a substantial prize for being selected. For this reason, we’ve teamed up with IRISnet to offer ~500,000 IRIS in prizes!
          </div>
        </div>
        <div class="claimtime mb-lg-6">
          <div> Phase one: 2022.9.1–10.31 Ended, 8 Winners Selected</div>
          <div> Phase two: 2022.11.10–2023.1.10 Ended, 6 Winners Selected</div>
          <div> The third phase of this campaign: 2023.2.8-5.8 23:59 UTC+8</div>
        </div>
        <div class="event">
            <div class="title-event">Event Prizes</div>
            <div >· Creator Certification</div>
            <div>· NFT Recommendation</div>
            <div>· Official Promotion</div>
            <div>· Creator Rewards: 15,000 IRIS</div>
            <div>· Referral Rewards: 1,500 IRIS</div> 
            <div>· Marketing Access NFT</div> 
            <br/>
        </div>
        <div class="prize">
          <div>
            <div class="rules">How to participate?</div>
            <div class="titles">1. Mint an NFT collection on UptickNFT.com and list it for sale.</div>
            <div class="titles">
             2. Post a tweet showcasing your collection submission and tag @upticknft, using the hashtag #UptickCollection
            </div>
            <div class="titles">
             3. Fill in the  <a target="blank" href="https://forms.gle/69BMsoULMqH9rLRMA"
                >Google Form</a
              > and Uptick Official will contact you for further communication.
            </div>
            <br/>
          </div>
        </div>
        <div class="Qualification">
          <div class="Qualification_title">Collection Qualification</div>
          <div>We welcome all of the talented creators in the community to participate in releasing an original collection on the platform. Please note you must abide by the following requirements:</div>
          <div>·<span>Originality</span>: Plagiarism is prohibited</div>
          <div>·<span>First Release</span>: The collection must not be minted on other NFT platforms</div>
          <div>·<span>Collection</span>: Minted as a ‘Collection’ on UptickNFT.com</div>
          <div>·<span>Quantity</span>: Minimum of 10 NFTs per ‘Collection’</div>
          <div>·<span>Theme</span>: All NFTs in the ‘Collection’ must follow a consistent theme.</div>
          <div>·<span>Release Period</span>: Update and add to collection within 2 months post-launch.</div>
          <div>Detailed introduction please check <a target="blank" href="https://uptickproject.medium.com/uptick-irisnet-nft-collection-launchpad-event-phase-i-recap-phase-ii-announcement-f788546b8690"
                >Medium</a> </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      d1:'',
      d2:'',
      h1:'',
      h2:'',
      m1:'',
      m2:'',
      s1:'',
      s2:'',
      isPhotographyEnd:false,
      isStart:false,
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
   mounted() {
     this.Time();
     var timestamp = Date.parse(new Date()).toString();
      let nowTimeStamp = timestamp.substr(0, 10);
      // collection结束时间
      let photographyEndTimeStamp = 1675753600000;
        if (nowTimeStamp > photographyEndTimeStamp) {
          this.isStart = false
          this.isPhotographyEnd = true;
          console.log(nowTimeStamp);
          console.log(photographyEndTimeStamp);
          console.log("结束");
      } else {
        this.isStart = true
        this.isPhotographyEnd = false;
        console.log(nowTimeStamp);
        console.log(photographyEndTimeStamp);
        console.log("开始");
      }

   },
  methods: {
    
    toApply(){
      //https://forms.gle/69BMsoULMqH9rLRMA
   window.open("https://forms.gle/69BMsoULMqH9rLRMA");
    },
    getTime() {
      // 摄像作品结束时间
      let photographyEndTimeStamp = 1683561599000;
       this.now = new Date().valueOf()    
        this.end = photographyEndTimeStamp;

          if (this.now < this.end) {
            this.seconds = (this.end - this.now) / 1000;

          }else{
            console.log('活动结束')
          }
     },
      // 天 时 分 秒 格式化函数
      countDown() {
        let d = parseInt(this.seconds / (24 * 60 * 60));
        let day =  d < 10 ? "0" + d :  d;
        this.d1 = String(day).split("")[0]
        this.d2 = String(day).split("")[1]
        //let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
        let h = parseInt((this.seconds / (60 * 60)) % 24);
        let hour = h < 10 ? "0" + h : h;
        this.h1 = String(hour).split("")[0]
        this.h2 = String(hour).split("")[1]
        let m = parseInt((this.seconds / 60) % 60);
        let minutes = m < 10 ? "0" + m : m;
        this.m1 = String(minutes).split('')[0];
        this.m2 = String(minutes).split('')[1];
        let s = parseInt(this.seconds % 60);
        let second = s < 10 ? "0" + s : s;
        this.s1 = String(second).split("")[0]
        this.s2 = String(second).split("")[1]
      },
      //定时器没过1秒参数减1
      Time() {
        setInterval(() => {
          if (this.seconds > 0) {
            this.seconds -= 1;
            this.countDown();
          } else {
            this.getTime();
          }
        }, 1000);
      },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  .content {
    margin-top: 50px;
    .left {
      width: 560px;
      // height: 550px;
      img {
        width: 560px;
        height: 700px;
        display: block;
        object-fit: contain;
        cursor: pointer;
      }
      .timer{
        // max-width:305px;
        // margin:17px auto 21px;
		justify-content: center;
		        margin-top:17px;
        .mao{
          
          font-family:Helvetica;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: 0px;
          color: #270645;
        }
        .bom{
          font-family:Helvetica;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #766983;
          text-align:center;
          margin-top:7px;
        }
        span{
          width:27px;
          height:36px;
          display:inline-block;
          text-align:center;
          line-height:36px;
          background-color: #270645;
	    border-radius: 5px;
          font-family:Helvetica;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
        }
       
      }
      .endDesc{
        width: 410px;
        margin-left: 71px;
        margin-top:20px;
         color: red;
      }
      .btn {
       
        border-radius: 25px;
        text-align: center;
        margin: 0 auto;
        .notClaim{
        width: 276px;
        height: 51px;
          border-radius: 25px;
        background-image: linear-gradient(#270645, #270645),
          linear-gradient(
            90deg,
            #d300fd 0%,
            #a439fe 26%,
            #7471ff 52%,
            #00fdcf 100%
          ),
          linear-gradient(#270645, #270645);
        background-blend-mode: normal, normal, normal;
       
          font-family:Helvetica;
          font-size: 23px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 51px;
          letter-spacing: 0px;
          color: #ffffff;

        }

        .claim { 
                  width: 276px;
        height: 51px;
           border-radius: 25px;
            background-color: #766983;
          font-family:Helvetica;
          font-size: 23px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 51px;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
    }
    .right {
      width: 588px;
      // height: 613px;
      .title {
        span {
           font-family: Helvetica;
          font-size: 45px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 45px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
	  
      .desc {
        .celebrate {
           max-width: 567px;
          font-family:Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 23px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .claimtime {
          div{
            font-family: Helvetica;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
          }
        // div {
        //   font-family:Helvetica;
        //   font-size: 13px;
        //   font-weight: normal;
        //   font-stretch: normal;
        //   line-height: 18px;
        //   letter-spacing: 0px;
        //   color: #270645;
        // }
      }
      .event{
             font-family: Helvetica;
                font-size: 13px;
                font-weight: normal;
                letter-spacing: 0px;
                color: #270645;
                line-height: 23px;
          .title-event{      
                font-weight: bold;
           
          }
        
      }
      .prize {
       .rules{
           font-family: Helvetica;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
       }
       .titles{
           font-family: Helvetica;
            font-size: 13px;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;

       }
      }
      .Qualification{
          font-family: Helvetica;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 23px;
        letter-spacing: 0px;
        color: #270645;
        width: 620px;
    .Qualification_title{
      font-weight: bold;
      
    }
    span{
       font-weight: bold;
    }
      }
    }
  }
  &.mobile {
    .content {
      margin-top: 30px;
      .left {
        max-width: 100%;
        min-height: 437px;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: contain;
          cursor: pointer;
        }
        .timer{
          // width:90%;
          // margin-left:14%;
          	justify-content: center;
		        margin-top:17px;
          span{
            width:20px;
            height:30px;
            font-size:15px;
            line-height:30px;
          }
        }
        .endDesc{
        width: 340px;
        margin-left: 7px;
        margin-top:20px;
        color: red;
      }
        .btn {
          width: 80%;
          .claim {
            width: 80%;
          }
        }
      }
      .right {
        max-width: 100%;
        min-height: 613px;
        .title {
          margin-bottom: 34px;
          span {
            font-size: 25px;
            line-height: 40px;
          }
        }
        .desc {
          // max-width:576px;
          margin-bottom: 28px;
        }
        .prize{
           min-height: 420px;
        }
        .claimtime {
          margin-bottom: 34px;
        }
      }
    }
  }
}
</style>
